import useAuth from "Auth/context";
import { MouseEvent } from "react";
import academyApi from "Academy/api";
import { useRequest } from "Core/hooks";
import styles from "./styles.module.scss";
import { Post } from "Content/types/Post";
import { draftjsUtils } from "Core/utils";
import { permissions } from "Auth/constants";
import MoreIcon from "Core/components/Icon/more";
import EditIcon from "Core/components/Icon/edit";
import DeleteIcon from "Core/components/Icon/delete";
import { Button, Dropdown, DropdownItem, FullscreenLoading, Img, Modal } from "Core/components";

type AcademyCardProps = {
  data: Post;
  refresh?: () => void;
};

const AcademyCard: React.FC<AcademyCardProps> = ({ data, refresh }) => {
  const auth = useAuth();
  const deleteAcademy = useRequest(academyApi.deleteAcademy);
  const tag = data.tags?.find((x) => x.primary)?.tag || data.tags[0]?.tag || "";

  const onPrevent = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const onEdit = () => {
    window.open(`${process.env.REACT_APP_URL}blog/admin/${data._id}`, "_self");
  };

  const onDelete = () => {
    Modal.confirm({
      title: "Are you sure you want to delete this blog?",
    }).then(() => {
      deleteAcademy.request(data._id!).then(() => {
        refresh?.();
      });
    });
  };

  return (
    <>
      <div className={styles.academyCard}>
        <div className={styles.academyCardImage}>
          <Img src={data.listingImageUrl} fit="cover" />
        </div>
        <div className={styles.academyCardContent}>
          <div className={styles.academyCardTitle}>{draftjsUtils.getPlainText(data.title.value)}</div>
          <div className={styles.academyCardFooter}>{tag && <div className={styles.academyCardTag}>{tag}</div>}</div>
        </div>
        {auth.user?.role === permissions.admin && !!refresh && (
          <div className={styles.academyCardActions} onClick={onPrevent}>
            <Dropdown>
              <Button circle>
                <MoreIcon />
              </Button>
              <DropdownItem onClick={onEdit}>
                <EditIcon />
                Edit
              </DropdownItem>
              <DropdownItem onClick={onDelete} className={styles.academyCardActionsDanger}>
                <DeleteIcon />
                Delete
              </DropdownItem>
            </Dropdown>
          </div>
        )}
      </div>
      <FullscreenLoading loading={deleteAcademy.loading} />
    </>
  );
};

export default AcademyCard;
